import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestCycleActions = () => {
  return (
    <LayoutComponent>
      <h2>More Test Cycle actions</h2>
      <div>
        <strong>Clone Test Cycle</strong>
        <p>
          Cloning a test cycle duplicates the associated test cases while
          clearing all test executions, comments, attachments, and linked issues
          from the new cycle. This allows you to start fresh with the same test
          cases.
        </p>
        <ol>
          <li>Select the test cycle you want to clone.</li>

          <li>
            Click the <strong>Clone</strong> button. If the test cycle is still
            active, a popup will appear, allowing you to either complete the
            current cycle before cloning or proceed with a new purpose/ type for
            the cloned cycle.
          </li>
          <li>
            The cloned test cycle will appear in the Test Cycles table with
            "(CLONE)" in the title. You can click on it to open and rename the
            cycle as needed.
          </li>
        </ol>
      </div>
      <div>
        <h2>Filter Test Cycles</h2>
        <p>
          Use filtering to quickly locate specific test cycles based on various
          criteria such as status, purpose, owner, or type (Manual or
          Automated).
        </p>
        <ol>
          <li>
            Click the <strong>Filter</strong> button in the Test Cycles view.
          </li>
          <li>A modal will open with filter options.</li>
          <li>Choose your filter criteria: status, purpose, owner, or type.</li>
          <li>
            Click <strong>Apply</strong> to activate the filter.
          </li>
          <li>
            Applied filters will be displayed below the search bar in the Test
            Cycles view.
          </li>
        </ol>
      </div>
    </LayoutComponent>
  )
}

export default TestCycleActions
